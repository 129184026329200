
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'Panel',
    props: {
        noPadding: {
            type: Boolean,
            default: false,
        },
        fitToScreen: {
            type: Boolean,
            default: false,
        },
        fullWidth: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        classes(): Record<string, boolean> {
            return {
                'panel--no-padding': this.noPadding,
                'panel--fit-to-screen': this.fitToScreen,
                'panel--full-width': this.fullWidth,
            };
        },
    },
});
